<template>
  <div>
    <Overlay :overlay="overlay" />
    <v-card class="elevation-0" max-height="300">
      <v-row no-gutters>
        <v-col cols="12" xs="12" sm="12" md="5">
          <v-toolbar class="elevation-0">
            <div class="heading1 PrimaryFontColor mr-3">Templates</div>
          </v-toolbar>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="7">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-select
              outlined
              class="field_height field_label_size FontSize mt-8 maxWidthLarge"
              text
              dense
              label="Template"
              v-model="Template"
              :items="TemplateItems"
            ></v-select>
          </v-toolbar>
        </v-col>
      </v-row>
      <div v-if="this.Template == 'Template 1'">
        <v-row no-gutters>
          <v-col cols="12" sm="12" xs="12" md="3" class="px-1">
            <v-card outlined class="overflow-y-auto elevation-10">
              <v-data-table
                dense
                :height="height"
                :fixed-header="fixHeader"
                hide-default-footer
                :headers="headers"
                :items="Listitems"
                class="elevation-0"
              >
                <template v-slot:top>
                  <v-card outlined>
                    <v-card-subtitle>
                      <div class="font-weight-bold primary--text">Operational Parameters</div>
                    </v-card-subtitle>
                  </v-card>
                </template>
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.actualt`]="{ item }">
                  <div class="grey lighten-3 FontSize" align="center" v-if="item.actualt">
                    {{ item.actualt }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.comm`]="{ item }">
                  <div class="FontSize" v-if="item.comm">
                    {{ item.comm }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
              </v-data-table>
              <v-toolbar outlined class="primary--text font-weight-bold elevation-0">Alarms</v-toolbar>
              <v-data-table
                dense
                :headers="headers"
                :items="Listalarams"
                hide-default-header
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
              </v-data-table>
              <!-- </v-col> -->
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6">
            <v-card outlined class="elevation-5">
              <v-card-actions class="py-1">
                <div class="font-weight-bold primary--text">Summary</div>
                <v-spacer></v-spacer>
                <div>
                  <span>
                    <span class="font-weight-bold">Asset Name:</span><span class="green-text"> 800 KLD ETP</span>
                  </span>
                  <br />
                  <span class="pt-n4">
                    <span class="font-weight-bold">Asset ID:</span>
                    <span>etp801</span>
                  </span>
                </div>
              </v-card-actions>
            </v-card>
            <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
              <v-row no-gutters class="mt-2">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card class="elevation-0 mt-2 ml-2 pa-3" outlined flat>
                    <div class="font-weight-bold ml-2 text--primary">
                      <u>UF ( 40M³/Hr )</u>
                    </div>
                    <div class="font-weight-bold ml-2">
                      Step:<span class="fontsize font-weight-bold green--text">
                        <v-icon v-on="on" small color="green" class="ml-2"> mdi-checkbox-blank-circle</v-icon>
                        UF FILTERATION SERVICE</span
                      >
                    </div>
                    <div class="font-weight-bold ml-2">Recovery(%):</div>
                    <div class="font-weight-bold ml-2">Status</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="ListStatus"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                    >
                      <template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                    <div class="font-weight-bold ml-2">Feedback</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="Listfeedback"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                    >
                      <template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card class="elevation-0 ml-3 mt-2 mr-2 pa-3" outlined flat>
                    <div class="font-weight-bold ml-2 text--primary">
                      <u>RO1 ( 28M³/Hr )</u>
                    </div>
                    <div class="font-weight-bold ml-2">
                      Step:<span class="fontsize font-weight-bold red--text">
                        <v-icon v-on="on" small color="red" class="ml-2"> mdi-checkbox-blank-circle</v-icon>
                        IDLE STEP</span
                      >
                    </div>
                    <div class="font-weight-bold ml-2">
                      Recovery(%):<span class="fontsize font-weight-bold red--text ml-1">IDLE</span>
                    </div>
                    <div class="font-weight-bold ml-2">Status</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="ListStatus1"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                      ><template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                    <div class="font-weight-bold ml-2">Feedback</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="Listfeedback1"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                      ><template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="red" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-row no-gutters class="mt-3">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-card class="elevation-0 ml-2 pa-3" outlined flat>
                      <div class="font-weight-bold ml-2 text--primary">
                        <u> RO2 ( 6.5 M³/Hr )</u>
                      </div>
                      <div class="font-weight-bold ml-2">Step:</div>
                      <div class="font-weight-bold ml-2">Recovery(%):</div>
                      <div class="font-weight-bold ml-2">Status</div>
                      <v-data-table
                        dense
                        :headers="headers1"
                        :items="Liststatus2"
                        hide-default-header
                        hide-default-footer
                        class="elevation-1 grey lighten-3"
                      >
                        <template v-slot:[`item.parameter`]="{ item }">
                          <div class="FontSize" v-if="item.parameter">
                            {{ item.parameter }}
                          </div>
                          <!-- <div v-else>-</div> -->
                        </template>
                        <template v-slot:[`item.Actions`]="{}">
                          <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                        </template>
                      </v-data-table>
                      <div class="font-weight-bold ml-2">Feedback</div>
                      <v-data-table
                        dense
                        :headers="headers1"
                        :items="Listfeedback2"
                        hide-default-header
                        hide-default-footer
                        class="elevation-1 grey lighten-3"
                      >
                        <template v-slot:[`item.parameter`]="{ item }">
                          <div class="FontSize" v-if="item.parameter">
                            {{ item.parameter }}
                          </div>
                          <!-- <div v-else>-</div> -->
                        </template>
                        <template v-slot:[`item.Actions`]="{}">
                          <v-icon v-on="on" x-small color="red" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-row>
            </v-card>

            <!-- </v-col> -->
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="3" class="px-1">
            <v-card outlined class="elevation-5">
              <v-card-subtitle>
                <div class="font-weight-bold primary--text">Daily Logs</div>
              </v-card-subtitle>
            </v-card>
            <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
              <v-data-table
                dense
                :headers="headers3"
                :items="ListDailylogs"
                hide-default-header
                hide-default-footer
                class="elevation-0"
              >
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>

                <template v-slot:[`item.start`]="{ item }">
                  <div class="FontSize" v-if="item.start">
                    {{ item.start }}
                  </div>
                  <!-- <v-divider class="mr-10" vertical></v-divider> -->
                  <div class="FontSize" v-if="item.parameter == 'UF Start / Stop'">11.27.17 AM</div>
                  <!-- <div
                      class="FontSize"
                      v-if="item.parameter == 'UF Start / Stop'"
                    >
                      11.20.17 AM
                    </div> -->
                  <!-- <div
                      class="FontSize"
                      v-if="item.parameter == 'UF Start / Stop'"
                    >
                      10.50.45 AM
                    </div> -->
                  <!-- <div
                      class="FontSize"
                      v-if="item.parameter == 'UF Start / Stop'"
                    >
                      10.15.45 AM
                    </div> -->
                </template>
                <template v-slot:[`item.stop`]="{ item }">
                  <div class="FontSize" v-if="item.stop">
                    {{ item.stop }}
                  </div>
                  <div class="FontSize" v-if="item.parameter == 'UF Start / Stop'">8.27.17 PM</div>
                  <!-- <div
                      class="FontSize"
                      v-if="item.parameter == 'UF Start / Stop'"
                    >
                      4.20.12PM
                    </div>
                    <div
                      class="FontSize"
                      v-if="item.parameter == 'UF Start / Stop'"
                    >
                      5.20.12PM
                    </div>
                    <div
                      class="FontSize"
                      v-if="item.parameter == 'UF Start / Stop'"
                    >
                      6.20.12PM
                    </div> -->
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-if="this.Template == 'Template 2'">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="3">
            <v-card outlined :height="OPHeight" class="overflow-y-auto elevation-10">
              <v-data-table
                dense
                :fixed-header="fixHeader"
                hide-default-footer
                :headers="headers"
                :items="Listitems"
                class="elevation-0"
              >
                <template v-slot:top>
                  <v-card outlined>
                    <v-card-subtitle>
                      <div class="font-weight-bold primary--text">Operational Parameters</div>
                    </v-card-subtitle>
                  </v-card>
                </template>
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.actualt`]="{ item }">
                  <div class="grey lighten-3 FontSize" align="center" v-if="item.actualt">
                    {{ item.actualt }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.comm`]="{ item }">
                  <div class="FontSize" v-if="item.comm">
                    {{ item.comm }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
              </v-data-table>
              <v-toolbar outlined class="primary--text font-weight-bold elevation-0">Set Points</v-toolbar>
              <v-data-table dense :headers="headers4" :items="Listheader4" hide-default-footer class="elevation-1">
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.actualt`]="{ item }">
                  <div class="grey lighten-3 FontSize" align="center" v-if="item.actualt">
                    {{ item.actualt }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
              </v-data-table>
              <v-toolbar outlined class="primary--text font-weight-bold elevation-0">Alarms</v-toolbar>
              <v-data-table
                :height="height"
                dense
                :headers="headers"
                :items="Listalarams"
                hide-default-header
                hide-default-footer
                class="elevation-0"
              >
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
              </v-data-table>
              <!-- </v-col> -->
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="6" class="px-1">
            <v-card outlined class="elevation-5">
              <v-card-actions class="py-1">
                <div class="font-weight-bold primary--text">Summary</div>
                <v-spacer></v-spacer>
                <div>
                  <span>
                    <span class="font-weight-bold">Asset Name:</span><span class="green-text"> 800 KLD ETP</span>
                  </span>
                  <br />
                  <span class="pt-n4">
                    <span class="font-weight-bold">Asset ID:</span>
                    <span>etp801</span>
                  </span>
                </div>
              </v-card-actions>
            </v-card>
            <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
              <v-row no-gutters class="mt-1">
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card class="elevation-0 ml-2 mt-2" outlined>
                    <div class="font-weight-bold ml-2 text--primary">
                      <u>UF ( 40M³/Hr )</u>
                    </div>
                    <div class="font-weight-bold ml-2">
                      Step:<span class="fontsize font-weight-bold green--text">
                        <v-icon v-on="on" small color="green" class="ml-2"> mdi-checkbox-blank-circle</v-icon>
                        UF FILTERATION SERVICE</span
                      >
                    </div>
                    <div class="font-weight-bold ml-2">Recovery(%):</div>
                    <div class="font-weight-bold ml-2">Status</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="ListStatus"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                    >
                      <template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                    <div class="font-weight-bold ml-2">Feedback</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="Listfeedback"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                    >
                      <template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card class="elevation-0 ml-3 mt-2" outlined>
                    <div class="font-weight-bold ml-2 text--primary">
                      <u>RO1 ( 28M³/Hr )</u>
                    </div>
                    <div class="font-weight-bold ml-2">
                      Step:<span class="fontsize font-weight-bold red--text">
                        <v-icon v-on="on" small color="red" class="ml-2"> mdi-checkbox-blank-circle</v-icon>
                        IDLE STEP</span
                      >
                    </div>
                    <div class="font-weight-bold ml-2">
                      Recovery(%):<span class="fontsize font-weight-bold red--text ml-2">IDLE</span>
                    </div>
                    <div class="font-weight-bold ml-2">Status</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="ListStatus1"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                      ><template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                    <div class="font-weight-bold ml-2">Feedback</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="Listfeedback1"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                      ><template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="red" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-row no-gutters class="mt-3">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-card class="elevation-0 ml-2" outlined>
                      <div class="font-weight-bold ml-2 text--primary">
                        <u> RO2 ( 6.5 M³/Hr )</u>
                      </div>
                      <div class="font-weight-bold ml-2">
                        Step:
                        <v-icon v-on="on" small color="red" class="ml-2"> mdi-checkbox-blank-circle</v-icon>
                        <span class="red--text"> IDLE STEP</span>
                      </div>
                      <div class="font-weight-bold ml-2">Recovery(%):<span class="red--text"> IDLE </span></div>
                      <div class="font-weight-bold ml-2">Status</div>
                      <v-data-table
                        dense
                        :headers="headers1"
                        :items="Liststatus2"
                        hide-default-header
                        hide-default-footer
                        class="elevation-1 grey lighten-3"
                      >
                        <template v-slot:[`item.parameter`]="{ item }">
                          <div class="FontSize" v-if="item.parameter">
                            {{ item.parameter }}
                          </div>
                          <!-- <div v-else>-</div> -->
                        </template>
                        <template v-slot:[`item.Actions`]="{}">
                          <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                        </template>
                      </v-data-table>
                      <div class="font-weight-bold ml-2">Feedback</div>
                      <v-data-table
                        dense
                        :headers="headers1"
                        :items="Listfeedback2"
                        hide-default-header
                        hide-default-footer
                        class="elevation-1 grey lighten-3"
                      >
                        <template v-slot:[`item.parameter`]="{ item }">
                          <div class="FontSize" v-if="item.parameter">
                            {{ item.parameter }}
                          </div>
                          <!-- <div v-else>-</div> -->
                        </template>
                        <template v-slot:[`item.Actions`]="{}">
                          <v-icon v-on="on" x-small color="red" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-row>
            </v-card>
            <!-- </v-col> -->
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <v-card outlined class="elevation-5">
              <v-card-subtitle>
                <div class="font-weight-bold primary--text">Daily Logs</div>
              </v-card-subtitle>
            </v-card>
            <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
              <v-data-table
                dense
                :headers="headers3"
                :items="ListDailylogs"
                hide-default-header
                hide-default-footer
                class="elevation-0"
              >
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.start`]="{ item }">
                  <div class="FontSize" v-if="item.start">
                    {{ item.start }}
                  </div>
                </template>
                <template v-slot:[`item.stop`]="{ item }">
                  <div class="FontSize" v-if="item.stop">
                    {{ item.stop }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-if="this.Template == 'Template 3'">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="3">
            <v-card class="ml-2 mr-2" height="99%">
              <v-data-table
                :height="height"
                dense
                :fixed-header="fixHeader"
                hide-default-footer
                :headers="headers"
                :items="Listitems"
                class="elevation-0"
              >
                <template v-slot:top>
                  <v-card outlined>
                    <v-card-subtitle>
                      <div class="font-weight-bold primary--text">Operational Parameters</div>
                    </v-card-subtitle>
                  </v-card>
                </template>
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.actualt`]="{ item }">
                  <div class="grey lighten-3 FontSize" align="center" v-if="item.actualt">
                    {{ item.actualt }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.comm`]="{ item }">
                  <div class="FontSize" v-if="item.comm">
                    {{ item.comm }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
              </v-data-table>
              <v-toolbar outlined class="primary--text font-weight-bold elevation-0">Set Points</v-toolbar>
              <v-data-table dense :headers="headers4" :items="Listheader4" hide-default-footer class="elevation-0">
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.actualt`]="{ item }">
                  <div class="grey lighten-3 FontSize" align="center" v-if="item.actualt">
                    {{ item.actualt }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
              </v-data-table>
              <!-- </v-col> -->
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="6" class="px-1">
            <v-card outlined class="elevation-5">
              <v-card-actions class="py-1">
                <div class="font-weight-bold primary--text">Summary</div>
                <v-spacer></v-spacer>
                <div>
                  <span>
                    <span class="font-weight-bold">Asset Name:</span><span class="green-text"> 800 KLD ETP</span>
                  </span>
                  <br />
                  <span class="pt-n4">
                    <span class="font-weight-bold">Asset ID:</span>
                    <span>etp801</span>
                  </span>
                </div>
              </v-card-actions>
            </v-card>
            <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
              <v-divider></v-divider>
              <v-row no-gutters class="mt-1">
                <v-col cols="12" sm="12" xs="12" md="6">
                  <v-card class="elevation-0 ml-2 mt-2" outlined>
                    <div class="font-weight-bold ml-2 text--primary">
                      <u>UF ( 40M³/Hr )</u>
                    </div>
                    <div class="font-weight-bold ml-2">
                      Step:<span class="fontsize font-weight-bold green--text">
                        <v-icon v-on="on" small color="green" class="ml-2"> mdi-checkbox-blank-circle</v-icon>
                        UF FILTERATION SERVICE</span
                      >
                    </div>
                    <div class="font-weight-bold ml-2">Recovery(%):</div>
                    <div class="font-weight-bold ml-2">Status</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="ListStatus"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                    >
                      <template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                    <div class="font-weight-bold ml-2">Feedback</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="Listfeedback"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                    >
                      <template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6">
                  <v-card class="elevation-0 ml-3 mt-2" outlined>
                    <div class="font-weight-bold ml-2 text--primary">
                      <u>RO1 ( 28M³/Hr )</u>
                    </div>
                    <div class="font-weight-bold ml-2">
                      Step:<span class="fontsize font-weight-bold red--text">
                        <v-icon v-on="on" small color="red" class="ml-2"> mdi-checkbox-blank-circle</v-icon>
                        IDLE STEP</span
                      >
                    </div>
                    <div class="font-weight-bold ml-2">
                      Recovery(%):<span class="fontsize font-weight-bold red--text ml-2">IDLE</span>
                    </div>
                    <div class="font-weight-bold ml-2">Status</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="ListStatus1"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                      ><template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                    <div class="font-weight-bold ml-2">Feedback</div>
                    <v-data-table
                      dense
                      :headers="headers1"
                      :items="Listfeedback1"
                      hide-default-header
                      hide-default-footer
                      class="elevation-1 grey lighten-3"
                      ><template v-slot:[`item.parameter`]="{ item }">
                        <div class="FontSize" v-if="item.parameter">
                          {{ item.parameter }}
                        </div>
                        <!-- <div v-else>-</div> -->
                      </template>
                      <template v-slot:[`item.Actions`]="{}">
                        <v-icon v-on="on" x-small color="red" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                <v-row no-gutters class="mt-3">
                  <v-col cols="12" xs="12" sm="12" md="6">
                    <v-card class="elevation-0 ml-2" outlined>
                      <div class="font-weight-bold ml-2 text--primary">
                        <u> RO2 ( 6.5 M³/Hr )</u>
                      </div>
                      <div class="font-weight-bold ml-2">Step:</div>
                      <div class="font-weight-bold ml-2">Recovery(%):</div>
                      <div class="font-weight-bold ml-2">Status</div>
                      <v-data-table
                        dense
                        :headers="headers1"
                        :items="Liststatus2"
                        hide-default-header
                        hide-default-footer
                        class="elevation-1 grey lighten-3"
                      >
                        <template v-slot:[`item.parameter`]="{ item }">
                          <div class="FontSize" v-if="item.parameter">
                            {{ item.parameter }}
                          </div>
                          <!-- <div v-else>-</div> -->
                        </template>
                        <template v-slot:[`item.Actions`]="{}">
                          <v-icon v-on="on" x-small color="green" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                        </template>
                      </v-data-table>
                      <div class="font-weight-bold ml-2">Feedback</div>
                      <v-data-table
                        dense
                        :headers="headers1"
                        :items="Listfeedback2"
                        hide-default-header
                        hide-default-footer
                        class="elevation-1 grey lighten-3"
                      >
                        <template v-slot:[`item.parameter`]="{ item }">
                          <div class="FontSize" v-if="item.parameter">
                            {{ item.parameter }}
                          </div>
                          <!-- <div v-else>-</div> -->
                        </template>
                        <template v-slot:[`item.Actions`]="{}">
                          <v-icon v-on="on" x-small color="red" class="mr-2"> mdi-checkbox-blank-circle</v-icon>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-row>
            </v-card>
            <!-- </v-col> -->
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3">
            <v-card outlined class="elevation-5">
              <v-card-subtitle>
                <div class="font-weight-bold primary--text">Daily Logs</div>
              </v-card-subtitle>
            </v-card>
            <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
              <v-data-table
                :height="height"
                dense
                :headers="headers3"
                :items="ListDailylogs"
                hide-default-header
                hide-default-footer
                class="elevation-0"
              >
                <template v-slot:[`item.parameter`]="{ item }">
                  <div class="FontSize" v-if="item.parameter">
                    {{ item.parameter }}
                  </div>
                  <!-- <div v-else>-</div> -->
                </template>
                <template v-slot:[`item.start`]="{ item }">
                  <div class="FontSize" v-if="item.start">
                    {{ item.start }}
                  </div>
                </template>
                <template v-slot:[`item.stop`]="{ item }">
                  <div class="FontSize" v-if="item.stop">
                    {{ item.stop }}
                  </div>
                </template>
              </v-data-table>
              <v-card outlined class="elevation-5">
                <v-card-subtitle>
                  <div class="font-weight-bold primary--text">Alarms</div>
                </v-card-subtitle>
              </v-card>
              <v-card outlined :height="Height" class="overflow-y-auto elevation-5">
                <v-data-table
                  dense
                  :headers="headers"
                  :items="Listalarams"
                  hide-default-header
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:[`item.parameter`]="{ item }">
                    <div class="FontSize" v-if="item.parameter">
                      {{ item.parameter }}
                    </div>
                    <!-- <div v-else>-</div> -->
                  </template>
                </v-data-table>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>
<script>
import Overlay from "@/components/Extras/Overlay.vue";
export default {
  components: { Overlay },
  data: () => ({
    Height: 0,
    OPHeight: 0,
    scrollInvoked: 0,
    overlay: false,
    Template: "Template 1",
    TemplateItems: ["Template 1", "Template 2", "Template 3"],
    headers: [
      {
        text: "Parameter",
        value: "parameter",
        align: "start",
        sortable: false,
      },
      { text: "Actual", value: "actualt" },
      { text: "Comm ", value: "comm" },
    ],
    headers1: [
      {
        text: "UF Start/Stop",
        value: "parameter",
        align: "start",
        sortable: false,
      },
      { text: "Action", value: "Actions" },
    ],
    headers4: [
      {
        text: "Parameter",
        value: "parameter",
        align: "start",
        sortable: false,
      },
      { text: "value", value: "actualt" },
    ],

    headers2: [
      {
        text: "Parameter",
        value: "parameter",
        align: "start",
        sortable: false,
      },
      { text: "Action", value: "Actions" },
    ],
    headers3: [
      {
        text: "Parameter",
        value: "parameter",
        align: "start",
        sortable: false,
      },
      { text: "start", value: "start", width: "80%" },
      { text: "stop", value: "stop" },
    ],
    Listitems: [
      {
        parameter: "Uf Feed pressure(Bar)",
        actualt: 0.0,
        comm: 1,
      },
      {
        parameter: "RO 1 Permeate pH",
        actualt: 4.13,
        comm: 6.5,
      },
      {
        parameter: "RO 2 Permeate Flow(M³/Hr)",
        actualt: 0.0,
        comm: 6.5,
      },
      {
        parameter: "RO 2 Feed Flow (M³/Hr)",
        actualt: 0.0,
        comm: 6.5,
      },
      {
        parameter: "RO 2 Permeate pH",
        actualt: 7.58,
        comm: 8.5,
      },
      {
        parameter: "RO 1 Permeate Flow (M³/Hr)",
        actualt: 0.0,
        comm: 28,
      },
      {
        parameter: "Uf Permeate Flow(M³/Hr)",
        actualt: 46.26,
        comm: 40,
      },
      {
        parameter: "ORP at RO 1 Inlet (mv)",
        actualt: 226.27,
        comm: 350,
      },
      {
        parameter: "RO 2 Permeate Conductivity (µS/cm)",
        actualt: 404.35,
        comm: 150,
      },
      {
        parameter: "RO 2 Permeate Conductivity (µS/cm)",
        actualt: 404.35,
        comm: 150,
      },
    ],
    Listheader4: [{ parameter: "Pressure Pneumatic left (Bar)", actualt: 8.46 }, { actualt: 5.66 }],
    Listalarams: [
      {
        parameter: " UFBW Tank Level High 14/11/2022, 2:11:36 pm ",
      },
      {
        parameter: "Clarified Water Storage Tank Low 14/11/2022, 10:23:01 am ",
      },
      {
        parameter: "UFBW Tank Level Low 14/11/2022, 10:23:01 am ",
      },
      {
        parameter: "UFBW Tank Level Low 14/11/2022, 10:23:01 am ",
      },
      {
        parameter: "UFBW Tank Level Low 14/11/2022, 10:23:01 am ",
      },
      {
        parameter: "Clarified Water Storage Tank Low 14/11/2022, 10:23:01 am ",
      },
    ],
    ListStatus: [
      { parameter: "Clarified Water Storage Tank Low " },
      { parameter: "  UF Differential Pressure High  " },
      { parameter: "  UFBW Tank Level Very Low  " },
      { parameter: " UFBW Tank Level Low   " },
      { parameter: " UFBW Tank Level High  " },
      { parameter: " Instrument Air Pressure Low   " },
    ],
    ListStatus1: [
      { parameter: " RO 1 Antiscalant Tank Low " },
      { parameter: "Permeate Storage Tank Level Very Low" },
      { parameter: "Permeate Storage Tank Level Low " },
      { parameter: "Permeate Storage Tank High" },
      { parameter: "RO1 Inlet Pressure Low" },
    ],
    Liststatus2: [
      { parameter: "RO2 Antiscalant Tank Low  " },
      { parameter: "RO1 Reject Water Tank Low " },
      { parameter: "RO1 Reject Water Tank High " },
      { parameter: "RO2 Inlet Pressure Low " },
    ],

    Listfeedback: [
      {
        parameter: "RWP-1 Feedback ",
      },
      { parameter: "RWP-2 Feedback" },
      { parameter: "UFBWP-1 Feedback" },
      { parameter: "UFBWP-2 Feedback " },
      { parameter: "CDP-1 Feedback" },
      { parameter: "ADP-1 Feedback " },
    ],
    Listfeedback1: [
      { parameter: "ROFP-1 Feedback " },
      {
        parameter: "ROFP-2 Feedback",
      },
      { parameter: "ADP-2 Feedback" },
      { parameter: "HPP-1 Feedback" },
      { parameter: "HPP-2 Feedback " },
    ],
    Listfeedback2: [
      { parameter: "ROFP-3 Feedback " },
      {
        parameter: "ROFP-4 Feedback",
      },

      { parameter: "HPP-3 Feedback" },
      { parameter: "HPP-4 Feedback " },
    ],

    ListDailylogs: [
      {
        parameter: "UF Start / Stop",
        start: "Start",
        stop: "Stop ",
      },
      {
        parameter: "UF total hours of Operation :",
        start: 0.0,
        stop: "05:47:01",
      },
      {
        parameter: "UF total hours of Operation :",
        start: 0.0,
        stop: "05:47:01",
      },

      {
        parameter: "UF Water Produced (M³):",
        start: 0.0,
        stop: "197.38 ",
      },
      {
        parameter: "RO1 Start / Stop",
        start: "start",
        stop: "stop",
      },
      {
        parameter: "UF total hours of Operation :",
        start: 0.0,
        stop: "05:47:01",
      },
      {
        parameter: "RO1 total hours of Operation :",
        start: 0.0,
        stop: "-",
      },
      {
        parameter: "RO1 Water Produced (M³):",
        start: 0.0,
        stop: "stop",
      },
      {
        parameter: "RO2 Start / Stop",
        start: 0.0,
        stop: "-",
      },
      {
        parameter: "RO2 total hours of Operation :",
        start: 0.0,
        stop: "00:00:00 ",
      },
      {
        parameter: "DMF Manual Backwash (M³/Day):",
        start: 0.0,
        stop: "",
      },
      {
        parameter: "RO2 Water Produced (M³):",
        start: 0.0,
        stop: "-",
      },
      {
        parameter: "DMF Manual Backwash (M³/Day):",
        start: 0.0,
        stop: "",
      },
      {
        parameter: "UF Plant Inlet (M³/Day):",
        start: 0.0,
        stop: "00:00:00 ",
      },
      {
        parameter: "RO2 Start / Stop",
        start: 0.0,
        stop: "-",
      },
    ],
  }),
  mounted() {
    this.height = window.innerHeight - 500;
    this.Height = window.innerHeight - 217;
    this.OPHeight = window.innerHeight - 160;
  },
  methods: {
    onScroll() {
      this.scrollInvoked++;
    },
  },
};
</script>
