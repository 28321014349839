var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Overlay',{attrs:{"overlay":_vm.overlay}}),_c('v-card',{staticClass:"elevation-0",attrs:{"max-height":"300"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"5"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('div',{staticClass:"heading1 PrimaryFontColor mr-3"},[_vm._v("Templates")])])],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"7"}},[_c('v-toolbar',{staticClass:"elevation-0"},[_c('v-spacer'),_c('v-select',{staticClass:"field_height field_label_size FontSize mt-8 maxWidthLarge",attrs:{"outlined":"","text":"","dense":"","label":"Template","items":_vm.TemplateItems},model:{value:(_vm.Template),callback:function ($$v) {_vm.Template=$$v},expression:"Template"}})],1)],1)],1),(this.Template == 'Template 1')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","xs":"12","md":"3"}},[_c('v-card',{staticClass:"overflow-y-auto elevation-10",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","height":_vm.height,"fixed-header":_vm.fixHeader,"hide-default-footer":"","headers":_vm.headers,"items":_vm.Listitems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Operational Parameters")])])],1)]},proxy:true},{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.actualt",fn:function(ref){
var item = ref.item;
return [(item.actualt)?_c('div',{staticClass:"grey lighten-3 FontSize",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.actualt)+" ")]):_vm._e()]}},{key:"item.comm",fn:function(ref){
var item = ref.item;
return [(item.comm)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.comm)+" ")]):_vm._e()]}}],null,true)}),_c('v-toolbar',{staticClass:"primary--text font-weight-bold elevation-0",attrs:{"outlined":""}},[_vm._v("Alarms")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.Listalarams,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-actions',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Summary")]),_c('v-spacer'),_c('div',[_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset Name:")]),_c('span',{staticClass:"green-text"},[_vm._v(" 800 KLD ETP")])]),_c('br'),_c('span',{staticClass:"pt-n4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset ID:")]),_c('span',[_vm._v("etp801")])])])],1)],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-row',{staticClass:"mt-2",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 mt-2 ml-2 pa-3",attrs:{"outlined":"","flat":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v("UF ( 40M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Step:"),_c('span',{staticClass:"fontsize font-weight-bold green--text"},[_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")]),_vm._v(" UF FILTERATION SERVICE")],1)]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Recovery(%):")]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.ListStatus,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-3 mt-2 mr-2 pa-3",attrs:{"outlined":"","flat":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v("RO1 ( 28M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Step:"),_c('span',{staticClass:"fontsize font-weight-bold red--text"},[_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")]),_vm._v(" IDLE STEP")],1)]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Recovery(%):"),_c('span',{staticClass:"fontsize font-weight-bold red--text ml-1"},[_vm._v("IDLE")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.ListStatus1,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback1,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-2 pa-3",attrs:{"outlined":"","flat":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v(" RO2 ( 6.5 M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Step:")]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Recovery(%):")]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Liststatus2,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback2,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","xs":"12","md":"3"}},[_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Daily Logs")])])],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers3,"items":_vm.ListDailylogs,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(item.start)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.start)+" ")]):_vm._e(),(item.parameter == 'UF Start / Stop')?_c('div',{staticClass:"FontSize"},[_vm._v("11.27.17 AM")]):_vm._e()]}},{key:"item.stop",fn:function(ref){
var item = ref.item;
return [(item.stop)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.stop)+" ")]):_vm._e(),(item.parameter == 'UF Start / Stop')?_c('div',{staticClass:"FontSize"},[_vm._v("8.27.17 PM")]):_vm._e()]}}],null,true)})],1)],1)],1)],1):_vm._e(),(this.Template == 'Template 2')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"3"}},[_c('v-card',{staticClass:"overflow-y-auto elevation-10",attrs:{"outlined":"","height":_vm.OPHeight}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","fixed-header":_vm.fixHeader,"hide-default-footer":"","headers":_vm.headers,"items":_vm.Listitems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Operational Parameters")])])],1)]},proxy:true},{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.actualt",fn:function(ref){
var item = ref.item;
return [(item.actualt)?_c('div',{staticClass:"grey lighten-3 FontSize",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.actualt)+" ")]):_vm._e()]}},{key:"item.comm",fn:function(ref){
var item = ref.item;
return [(item.comm)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.comm)+" ")]):_vm._e()]}}],null,true)}),_c('v-toolbar',{staticClass:"primary--text font-weight-bold elevation-0",attrs:{"outlined":""}},[_vm._v("Set Points")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers4,"items":_vm.Listheader4,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.actualt",fn:function(ref){
var item = ref.item;
return [(item.actualt)?_c('div',{staticClass:"grey lighten-3 FontSize",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.actualt)+" ")]):_vm._e()]}}],null,true)}),_c('v-toolbar',{staticClass:"primary--text font-weight-bold elevation-0",attrs:{"outlined":""}},[_vm._v("Alarms")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"height":_vm.height,"dense":"","headers":_vm.headers,"items":_vm.Listalarams,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","xs":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-actions',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Summary")]),_c('v-spacer'),_c('div',[_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset Name:")]),_c('span',{staticClass:"green-text"},[_vm._v(" 800 KLD ETP")])]),_c('br'),_c('span',{staticClass:"pt-n4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset ID:")]),_c('span',[_vm._v("etp801")])])])],1)],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-2 mt-2",attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v("UF ( 40M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Step:"),_c('span',{staticClass:"fontsize font-weight-bold green--text"},[_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")]),_vm._v(" UF FILTERATION SERVICE")],1)]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Recovery(%):")]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.ListStatus,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-3 mt-2",attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v("RO1 ( 28M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Step:"),_c('span',{staticClass:"fontsize font-weight-bold red--text"},[_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")]),_vm._v(" IDLE STEP")],1)]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Recovery(%):"),_c('span',{staticClass:"fontsize font-weight-bold red--text ml-2"},[_vm._v("IDLE")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.ListStatus1,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback1,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-2",attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v(" RO2 ( 6.5 M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Step: "),_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")]),_c('span',{staticClass:"red--text"},[_vm._v(" IDLE STEP")])],1),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Recovery(%):"),_c('span',{staticClass:"red--text"},[_vm._v(" IDLE ")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Liststatus2,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback2,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"3"}},[_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Daily Logs")])])],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers3,"items":_vm.ListDailylogs,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(item.start)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.start)+" ")]):_vm._e()]}},{key:"item.stop",fn:function(ref){
var item = ref.item;
return [(item.stop)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.stop)+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1):_vm._e(),(this.Template == 'Template 3')?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"3"}},[_c('v-card',{staticClass:"ml-2 mr-2",attrs:{"height":"99%"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"height":_vm.height,"dense":"","fixed-header":_vm.fixHeader,"hide-default-footer":"","headers":_vm.headers,"items":_vm.Listitems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',{attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Operational Parameters")])])],1)]},proxy:true},{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.actualt",fn:function(ref){
var item = ref.item;
return [(item.actualt)?_c('div',{staticClass:"grey lighten-3 FontSize",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.actualt)+" ")]):_vm._e()]}},{key:"item.comm",fn:function(ref){
var item = ref.item;
return [(item.comm)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.comm)+" ")]):_vm._e()]}}],null,true)}),_c('v-toolbar',{staticClass:"primary--text font-weight-bold elevation-0",attrs:{"outlined":""}},[_vm._v("Set Points")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"dense":"","headers":_vm.headers4,"items":_vm.Listheader4,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.actualt",fn:function(ref){
var item = ref.item;
return [(item.actualt)?_c('div',{staticClass:"grey lighten-3 FontSize",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(item.actualt)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('v-col',{staticClass:"px-1",attrs:{"cols":"12","sm":"12","xs":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-actions',{staticClass:"py-1"},[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Summary")]),_c('v-spacer'),_c('div',[_c('span',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset Name:")]),_c('span',{staticClass:"green-text"},[_vm._v(" 800 KLD ETP")])]),_c('br'),_c('span',{staticClass:"pt-n4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Asset ID:")]),_c('span',[_vm._v("etp801")])])])],1)],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-divider'),_c('v-row',{staticClass:"mt-1",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","xs":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-2 mt-2",attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v("UF ( 40M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Step:"),_c('span',{staticClass:"fontsize font-weight-bold green--text"},[_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")]),_vm._v(" UF FILTERATION SERVICE")],1)]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Recovery(%):")]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.ListStatus,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-3 mt-2",attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v("RO1 ( 28M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Step:"),_c('span',{staticClass:"fontsize font-weight-bold red--text"},[_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")]),_vm._v(" IDLE STEP")],1)]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v(" Recovery(%):"),_c('span',{staticClass:"fontsize font-weight-bold red--text ml-2"},[_vm._v("IDLE")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.ListStatus1,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback1,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"6"}},[_c('v-card',{staticClass:"elevation-0 ml-2",attrs:{"outlined":""}},[_c('div',{staticClass:"font-weight-bold ml-2 text--primary"},[_c('u',[_vm._v(" RO2 ( 6.5 M³/Hr )")])]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Step:")]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Recovery(%):")]),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Status")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Liststatus2,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"green"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)}),_c('div',{staticClass:"font-weight-bold ml-2"},[_vm._v("Feedback")]),_c('v-data-table',{staticClass:"elevation-1 grey lighten-3",attrs:{"dense":"","headers":_vm.headers1,"items":_vm.Listfeedback2,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.Actions",fn:function(ref){return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"x-small":"","color":"red"}},_vm.on),[_vm._v(" mdi-checkbox-blank-circle")])]}}],null,true)})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"12","md":"3"}},[_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Daily Logs")])])],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"height":_vm.height,"dense":"","headers":_vm.headers3,"items":_vm.ListDailylogs,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [(item.start)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.start)+" ")]):_vm._e()]}},{key:"item.stop",fn:function(ref){
var item = ref.item;
return [(item.stop)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.stop)+" ")]):_vm._e()]}}],null,true)}),_c('v-card',{staticClass:"elevation-5",attrs:{"outlined":""}},[_c('v-card-subtitle',[_c('div',{staticClass:"font-weight-bold primary--text"},[_vm._v("Alarms")])])],1),_c('v-card',{staticClass:"overflow-y-auto elevation-5",attrs:{"outlined":"","height":_vm.Height}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.Listalarams,"hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.parameter",fn:function(ref){
var item = ref.item;
return [(item.parameter)?_c('div',{staticClass:"FontSize"},[_vm._v(" "+_vm._s(item.parameter)+" ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }